import React, { useState, useEffect, useCallback, useRef } from "react";
import "./GameHolder.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Keyboard from "./Keyboard";
import Gameboard from "./Gameboard";
import { livesPerDifficulty } from "../settings/DifficultySlider";
import ThemeSelector from "./ThemeSelector";

import {
  createInitialSentence,
  generateRandomMapping,
  findNextBlank,
  getNiceTime,
  printLives,
} from "../helpers/utils";
import ThemeManager from "./ThemeManager";
import { Spinner } from "react-bootstrap";
import KeyboardInput from "../helpers/KeyboardInput";
import ThemeCompleted from "./ThemeCompleted";

function GameHolder({ difficulty, zoomLevel, enableHwKeyboard, showInfo }) {
  const [themes, setThemes] = useState({});
  const [theme, setTheme] = useState("");
  const [chapterName, setChapterName] = useState("");
  const [part, setPart] = useState("");
  const [selectedTheme, setSelectedTheme] = useState(undefined);
  const [themeLoaded, setThemeLoaded] = useState(false);
  const [themesLoaded, setThemesLoaded] = useState(false);
  const [themeCompleted, setThemeCompleted] = useState(false);
  const [sentencesLoaded, setSentencesLoaded] = useState(false);
  const [level, setLevel] = useState(0);
  const [sentence, setSentence] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [message, setMessage] = useState("");
  const [letterMapping, setLetterMapping] = useState({});
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);

  const [wrongIndex, setWrongIndex] = useState(undefined);

  const [lives, setLives] = useState(undefined);

  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(null);

  const themeRef = useRef(theme);
  const levelRef = useRef(level);

  useEffect(() => {
    if (!themeLoaded) {
      return;
    }

    setSentencesLoaded(selectedTheme?.sentences !== undefined);
  }, [themeLoaded, selectedTheme]);

  const handleSentencesLoaded = useCallback(
    (loadedSentences, selectedThemeRequest) => {
      if (themeLoaded) {
        if (loadedSentences === undefined) {
          setMessage("Error loading sentences 😭 Please try again later 😞");
        } else {
          if (selectedThemeRequest !== undefined) {
            selectedThemeRequest.sentences = loadedSentences;
            if (selectedTheme.uuid === selectedThemeRequest.uuid) {
              // currently chosen theme
              setSentencesLoaded(true);
            }
          }
        }
      }
    },
    [themeLoaded, selectedTheme]
  );

  useEffect(() => {
    if (isGameOver === false) {
      setLetterMapping(generateRandomMapping());
    }
  }, [theme, level, difficulty, isGameCompleted, isGameOver]);

  useEffect(() => {
    if (sentencesLoaded && isGameOver === false && sentence === "") {
      setSentence(
        createInitialSentence(
          selectedTheme.sentences[levelRef.current],
          difficulty
        )
      );
    }
  }, [sentencesLoaded, selectedTheme, level, difficulty, isGameOver, sentence]);

  function handleSelectLetter(index) {
    // Only allow selecting if it's a blank space
    if (sentence[index] === "_") {
      startTimer();
      setSelectedIndex(index);
      setMessage(""); // Reset the message
    }
  }

  const selectNextBlank = useCallback(() => {
    const nextBlankIndex = findNextBlank(sentence, selectedIndex, 1);
    setSelectedIndex(nextBlankIndex !== -1 ? nextBlankIndex : null);
    startTimer();
  }, [selectedIndex, sentence]);

  const selectPrevBlank = useCallback(() => {
    const prevBlankIndex = findNextBlank(sentence, selectedIndex, -1);
    setSelectedIndex(prevBlankIndex !== -1 ? prevBlankIndex : null);
    startTimer();
  }, [selectedIndex, sentence]);

  const startTimeRef = useRef(startTime);

  function startTimer() {
    if (startTimeRef.current === null) {
      let now = Date.now();
      setStartTime(now);
      startTimeRef.current = now;
      setElapsedTime(null);
    }
  }

  useEffect(() => {
    if (isGameCompleted) {
      // Stop the timer and calculate the elapsed time
      const currentTime = Date.now();

      let elapsed = getNiceTime(currentTime, startTimeRef.current);
      setElapsedTime(elapsed);
    }
  }, [isGameCompleted]);

  const handleKeyPress = useCallback(
    (letter) => {
      if (selectedIndex === null) {
        selectNextBlank();
        return;
      }

      if (isGameCompleted) return;

      if (selectedTheme?.sentences === undefined) return;

      if (
        selectedTheme.sentences[levelRef.current][
          selectedIndex
        ].toLowerCase() === letter.toLowerCase()
      ) {
        let newSentence = [...sentence];
        // Replace only the letter at the selected index
        newSentence[selectedIndex] =
          selectedTheme.sentences[levelRef.current][selectedIndex];
        setSentence(newSentence);
        selectNextBlank(); // Automatically select the next blank

        const allFilled = !newSentence.includes("_");
        if (allFilled) {
          setIsGameCompleted(true);
          setSelectedIndex(null);

          if (levelRef.current < selectedTheme.sentences.length - 1) {
            setMessage("Congratulations! You've guessed the sentence! 🥳");
          } else {
            setMessage("You've completed all levels! Congratulations! 🥳");
            setThemeCompleted(true);
          }
        }
        setWrongIndex(undefined);
      } else {
        setWrongIndex(selectedIndex);
        if (lives === 1) {
          setMessage("Oh no 😭 Better luck next time!");
          setLives(lives - 1);
          setIsGameOver(true);
        } else {
          setLives(lives - 1);
        }
      }
    },
    [
      selectedIndex,
      isGameCompleted,
      lives,
      sentence,
      selectedTheme,
      selectNextBlank,
    ]
  );

  const resetGameboardHelper = useCallback(() => {
    setThemeCompleted(false);
    setSelectedIndex(null);
    setIsGameCompleted(false);
    setIsGameOver(false);
    setMessage("");
    setSentence("");
    setStartTime(null);
    startTimeRef.current = null;
    setLives(livesPerDifficulty[difficultyRef.current]);
    setWrongIndex(undefined);
  }, []);

  const resetGameboard = useCallback(() => {
    if (difficulty === undefined) return;

    if (isGameCompleted) return;

    resetGameboardHelper();
  }, [difficulty, isGameCompleted, resetGameboardHelper]);

  useEffect(() => {
    difficultyRef.current = difficulty;
    resetGameboardHelper();
  }, [difficulty, resetGameboardHelper]);

  const handleNextLevel = useCallback(() => {
    if (selectedTheme?.sentences === undefined) return;

    if (levelRef.current < selectedTheme.sentences.length - 1) {
      setLevel(level + 1);
      levelRef.current++;
      setIsGameCompleted(false);
      resetGameboardHelper();
    }
  }, [resetGameboardHelper, level, selectedTheme]);

  const chapterNameRef = useRef(chapterName);

  const chooseThemePart = useCallback(
    (theme) => {
      setSelectedTheme(theme);
      setThemeLoaded(true);

      setLevel(0);
      levelRef.current = 0;
      setIsGameCompleted(false);
      resetGameboardHelper();
      setSentencesLoaded(false);
    },
    [resetGameboardHelper]
  );

  const setChapter = useCallback(
    (newChapterName) => {
      const newThemeChapters = themes[themeRef.current].chapters;
      if (newThemeChapters[newChapterName].uuid !== undefined) {
        // one part
        chooseThemePart(themes[themeRef.current].chapters[newChapterName]);
      } else {
        const firstPart = Object.keys(
          newThemeChapters[newChapterName].parts
        )[0];

        chooseThemePart(
          newThemeChapters[chapterNameRef.current].parts[firstPart]
        );
      }
    },
    [chooseThemePart, themes]
  );

  const handleThemeChange = useCallback(
    (event) => {
      const newTheme = event.target.value;
      setTheme(newTheme);
      themeRef.current = newTheme;

      setChapterName("");
      chapterNameRef.current = "";
      setPart("");
      if (Object.keys(themes).length === 0) return;

      if (themes[themeRef.current].chapters === undefined) {
        chooseThemePart(themes[newTheme]);
      } else {
        let firstChapter = Object.keys(themes[themeRef.current].chapters)[0];

        setChapterName(firstChapter);
        chapterNameRef.current = firstChapter;
        setChapter(firstChapter);
      }
    },
    [chooseThemePart, themes, setChapter]
  );

  const handleThemesLoaded = useCallback((loadedThemes) => {
    if (loadedThemes === undefined) {
      setMessage("Error loading themes 😭 Please try again later 😞");
    } else {
      setThemes(loadedThemes);
      setThemesLoaded(true);
      setMessage("");
    }
  }, []);

  const loadFirstTheme = useCallback(() => {
    handleThemeChange({
      target: {
        value: Object.keys(themes)[0],
      },
    });
  }, [themes, handleThemeChange]);

  useEffect(() => {
    if (themesLoaded === true) {
      loadFirstTheme();
    }
  }, [themesLoaded, loadFirstTheme]);

  const handleChapterNameChange = (event) => {
    const newChapterName = event.target.value;
    setChapterName(newChapterName);
    chapterNameRef.current = newChapterName;
    setChapter(chapterNameRef.current);
  };

  const handlePartChange = (event) => {
    const newPart = event.target.value;
    setPart(newPart);

    const newThemeChapters = themes[themeRef.current].chapters;
    chooseThemePart(newThemeChapters[chapterNameRef.current].parts[newPart]);
  };

  const handleOnContinue = useCallback(() => {
    if (isGameOver) {
      resetGameboard();
    } else if (isGameCompleted) {
      handleNextLevel();
    }
  }, [isGameCompleted, isGameOver, resetGameboard, handleNextLevel]);

  const difficultyRef = useRef(difficulty);

  return (
    <>
      <ThemeManager
        onThemesLoaded={handleThemesLoaded}
        onSentencesLoaded={handleSentencesLoaded}
        selectedTheme={selectedTheme}
      />
      {enableHwKeyboard === true &&
        showInfo === false &&
        themeLoaded &&
        sentencesLoaded &&
        sentence.length > 0 && (
          <KeyboardInput
            onAlphabetic={handleKeyPress}
            onArrowLeft={selectPrevBlank}
            onArrowRight={selectNextBlank}
            onContinue={handleOnContinue}
            enableContinue={isGameOver || isGameCompleted}
          />
        )}

      {showInfo === false && (
        <>
          <Row>
            <Col
              style={{
                display: "inline-block",
              }}
            >
              {themesLoaded && (
                <ThemeSelector
                  id="theme"
                  label="theme"
                  themes={themes}
                  theme={themeRef.current}
                  onThemeChange={handleThemeChange}
                />
              )}
              {themesLoaded &&
                themeLoaded &&
                themes[theme].chapters !== undefined && (
                  <>
                    <ThemeSelector
                      id="chapterSelector"
                      label="chapter"
                      themes={themes[themeRef.current].chapters}
                      theme={chapterName}
                      onThemeChange={handleChapterNameChange}
                    />
                    {chapterName !== "" &&
                      themes[theme].chapters[chapterName].uuid ===
                        undefined && (
                        <ThemeSelector
                          id="partSelector"
                          label="part"
                          themes={
                            themes[themeRef.current].chapters[chapterName].parts
                          }
                          theme={part}
                          onThemeChange={handlePartChange}
                        />
                      )}
                  </>
                )}
            </Col>
          </Row>

          {themeLoaded && sentencesLoaded && (
            <Row>
              <Col
                style={{
                  display: "inline-block",
                }}
              >
                <div className="levelHeader">
                  Level {levelRef.current + 1} of{" "}
                  {selectedTheme.sentences.length}
                </div>
              </Col>
              <Col
                style={{
                  marginLeft: "20px",
                  display: "inline-block",
                }}
              >
                Lives:{" "}
                {printLives(lives, livesPerDifficulty[difficultyRef.current])}
              </Col>
            </Row>
          )}

          <Row className="gameboard-container">
            {themeLoaded &&
              sentencesLoaded &&
              selectedTheme.sentences[levelRef.current].length ===
                sentence.length && (
                <Gameboard
                  sentence={sentence}
                  letterMapping={letterMapping}
                  selectedIndex={selectedIndex}
                  onSelectLetter={handleSelectLetter}
                  onGuessLetter={handleKeyPress}
                  initialSentence={selectedTheme.sentences[levelRef.current]}
                  difficulty={difficulty}
                  zoomLevel={zoomLevel}
                  wrongIndex={wrongIndex}
                />
              )}

            {themeLoaded === false && (
              <div className="loadingLevel">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading themes ⏳...</span>
                </Spinner>
              </div>
            )}
            {themeLoaded && sentencesLoaded === false && (
              <div className="loadingLevel">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading level ⏳...</span>
                </Spinner>
              </div>
            )}
          </Row>

          {message !== "" && (
            <Row>
              <p>{message}</p>
            </Row>
          )}

          <Row>
            {isGameCompleted && (
              <div className="levelTime">
                <p>Time: {elapsedTime}</p>
              </div>
            )}
          </Row>
          {isGameCompleted &&
            selectedTheme !== undefined &&
            level < selectedTheme.sentences.length - 1 && (
              <Row className="flex-middle">
                <button
                  className="goodButton nextLevel"
                  onClick={handleNextLevel}
                >
                  <div className="title"> Next Level</div>
                  <div className="returnKey">↵</div>
                </button>
              </Row>
            )}

          {isGameOver && (
            <Row className="flex-middle">
              <button
                className="goodButton restartLevel"
                onClick={resetGameboard}
              >
                <div className="title">Restart</div>
                <div className="returnKey">↵</div>
              </button>
            </Row>
          )}

          <Row className="keyboard-container">
            {themeLoaded && sentencesLoaded && sentence.length > 0 && (
              <Keyboard
                onKeyPress={handleKeyPress}
                sentence={sentence}
                initialSentence={selectedTheme.sentences[level]}
                isGameCompleted={isGameCompleted}
                isGameOver={isGameOver}
                difficulty={difficulty}
                selectNextBlank={selectNextBlank}
                selectPrevBlank={selectPrevBlank}
              />
            )}
          </Row>
          {themeLoaded && selectedTheme !== undefined && (
            <ThemeCompleted
              currentThemeUUID={selectedTheme.uuid}
              difficulty={difficultyRef.current}
              themeCompleted={themeCompleted}
              isGameCompleted={isGameCompleted}
              themes={themes}
              selectedTheme={selectedTheme}
            />
          )}
        </>
      )}
    </>
  );
}

export default GameHolder;
